@import "base/helpers";
@import "base/index";
@import "components/Avatar/_style";
@import "components/Header/_style";
@import "components/LinkButton/_style";
@import "components/Navigation/_style";
@import "components/Footer/_style";
@import "components/FlashMessage/_style";

.login-container {
  display: flex;
}

.login-header {
  margin-bottom: 0.8rem;
}

.login,
.signup {
  margin: 30px 0;
  border: none;
  float: left !important;
  width: 100%;
}

@include breakpoint(phablet) {
  .login,
  .signup {
    border-radius: 5px;
    padding: 30px 50px 60px;
    border: 3px solid #eee;
    width: 60%;
    margin: 3.5rem auto !important;
  }
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  &.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }

  &.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  }

  &.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }
}

input {
  margin-bottom: 1rem;
}

button {
  margin-top: 1rem;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

#or {
  margin: -11px auto 0;
  text-transform: uppercase;
  width: 40px !important;
  color: #c5c5c5;
  font-size: 14px;
  background-color: #fff;
}

h1,
h2,
h3,
h4 {
  letter-spacing: -1px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 900 !important;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

.input-text,
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.content-footer {
  margin-top: 0.8rem;
}

.error {
  margin-top: -0.5em;
  display: flex;
  padding-left: 1em;
}

.form-row:has(.error) {
  padding-bottom: 1rem;
}
