@import "base/helpers";

@include component(flash-message) {
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include option(success) {
    background-color: #55ca92;
    color: #fff;
  }

  @include option(info) {
    background-color: #328eeb;
    color: #fff;
  }

  @include option(warning) {
    background-color: #f5e273;
    color: #333;
  }

  @include option(danger) {
    background-color: #e85742;
    color: #fff;
  }

  @include option(error) {
    background-color: #e85742;
    color: #fff;
  }

  @include option(hidden) {
    display: none !important;
  }

  @include part(close) {
    float: right;
    font-size: 21px;
    line-height: 1;
    color: currentcolor;
    opacity: 100%;
    appearance: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:focus {
      opacity: 80%;
    }
  }

  @include part(close-text) {
    @include visuallyHidden;
  }

  button {
    margin-top: 0;
  }
}
